import React from 'react';
import { Link } from 'gatsby';
import Loadable from '@loadable/component';
// Utility Imports
import { getLocalizedURL } from '@src/helper';
// Style Imports
//import './styles.scss';
import '@components/content/cta/CTA';
// SEO
import Seo from '@components/utility/SEO';
// Structural Imports
import Layout from '@components/structure/Layout.jsx';
// Content Imports
import Anchor from '@components/content/anchor.jsx';
import Accordion from '@components/content/accordion/Accordion.jsx';
import Hero from '@components/content/hero/Hero.jsx';
import Image from '@components/content/fractals-image.jsx';
import PatientFeedbackResources from '@components/content/patientFeedback/page-resources/PatientFeedbackResources';
const StethoscopeLargeIcon = Loadable(() =>
	import('@components/content/icons/StethoscopeLarge.jsx')
);

const Resources = () => (
	<Layout addedClass='page--resources'>
		<Seo
			title='Recursos para pacientes | LUPKYNIS&reg; (voclosporina)'
			description='Obtenga respuestas a sus preguntas sobre LUPKYNIS y la nefritis lúpica. Obtenga más información sobre Aurinia Alliance® e inscríbase para recibir actualizaciones de LUPKYNIS. Consulte la Información de prescripción y seguridad completa y el RECUADRO DE ADVERTENCIA.'
			canonicalURL={'https://www.lupkynis.com' + getLocalizedURL('resources','es')}
		/>
		<Hero addedClass='bg-yellow hero--string'>
			<div className='col-wrapper'>
				<div className='container'>
					<div className='hero__content'>
						<h1 className='heading heading--accent'>RECURSOS</h1>
						<h2>
						Nunca <span>luche</span> solo
						</h2>
						<p>
						Su equipo de{' '}
							<Anchor url='https://www.auriniaalliance.com' target='_blank'>
								Aurinia Alliance<sup>&reg;</sup>
							</Anchor>{' '}
							se centra en proporcionarle lo que necesita en cada paso del recorrido de LUPKYNIS. Comprendemos que su recorrido por la nefritis lúpica es único y estamos aquí para escucharlo y apoyarlo a lo largo del camino. Su equipo incluye apoyo individual de un administrador de casos exclusivo del personal de enfermería que se comunicará con usted periódicamente para responder sus preguntas y ver cómo está.
						</p>
					</div>
					<div className='hero__image'>
						<Image
							data={{
								desktopSrc: 'resources/hero-es.png',
								mobileSrc: 'resources/hero--mobile-es.png',
								altText: 'Imagen de Crystal S., RN, enfermera administradora de casos',
							}}
						/>
					</div>
				</div>
			</div>
			<div className='hero__offers'>
				<div className='container'>
					<h3>Aurinia Alliance ofrece:</h3>
					<div className='hero__offers__allOffers'>
						<div className='hero__offers__allOffers_1'>
							<div className='hero__offers__icon__container'>
								<img
									src='/images/resources/resources-icon-1.svg'
									alt=''
									srcset=''
								/>
							</div>
							<p>Herramientas educativas</p>
						</div>

						<div className='hero__offers__allOffers_2'>
							<div className='hero__offers__icon__container'>
								<img
									src='/images/resources/resources-icon-2.svg'
									alt=''
									srcset=''
								/>
							</div>
							<p>
							Una farmacia de especialidades que le entregará LUPKYNIS directamente
							</p>
						</div>

						<div className='hero__offers__allOffers_3'>
							<div className='hero__offers__icon__container'>
								<img
									src='/images/resources/resources-icon-3.svg'
									alt=''
									srcset=''
								/>
							</div>
							<p>Ayuda para comprender su seguro y las opciones de financiamiento</p>
						</div>

						<div className='hero__offers__allOffers_4'>
							<div className='hero__offers__icon__container'>
								<img
									src='/images/resources/resources-icon-4.svg'
									alt=''
									srcset=''
								/>
							</div>
							<p>Copago de $0 para pacientes elegibles</p>
						</div>
					</div>

					<div className='hero__offers__bottomNotes'>
						<h4>
						En el 97 % de los casos*, los pacientes pagaron menos de $10 por su receta de LUPKYNIS.
						</h4>
						<p>
						*El 97 % de los envíos de LUPKYNIS entregados le costaron al paciente $10 o menos. Estos son pacientes con seguro comercial, Medicaid o Medicare desde enero de 2021 hasta junio de 2022
						</p>
						<h5>
						Obtenga más información sobre Aurinia Alliance e inscríbase para que le asignen un administrador de casos del personal de enfermería y comience a recibir apoyo personalizado.
						</h5>
					</div>
				</div>
			</div>
			<div className='hero__footer text-center'>
				<div className='container'>
					<div>
						<Link to={getLocalizedURL('registration','es')} className='button'>
						Inscríbase para obtener más información
						</Link>
					</div>
					<div className='hero__footer__right'>
						<div className='cta'>
							<p>
							o llame al{' '}
								<Anchor url='tel:18332874642'>
									1-833-AURINIA
									<br className='mobile-only' /> (1-833-287-4642)
								</Anchor>
								&nbsp;para&nbsp;inscribirse hoy
							</p>
						</div>
						<p className='bottom__note'>
						No olvide guardar este número en sus contactos telefónicos para que pueda comunicarse con nosotros fácilmente cuando necesite ayuda.
						</p>
					</div>
				</div>
			</div>
		</Hero>
		<section id='ConnectWithSomeone'>
			<div className='container'>
				<div className='ConnectWithSomeone_Content'>
					<h3>Comuníquese con alguien que ha pasado por lo mismo</h3>
					<p>
						<strong>
							Lupus Nephritis Peer Connect&trade;
						</strong>{' '}
						es un programa de tutoría que le permite hablar con alguien que pueda comprender lo que está atravesando. Nuestros mentores se ofrecen como voluntarios para realizar llamadas individuales y responder a sus preguntas, animarlo y compartir sus experiencias personales con la nefritis lúpica y el tratamiento con LUPKYNIS.
					</p>
					<Anchor
						url='https://www.lnpeerconnect.com/'
						target='_blank'
						linkClass='button'>
						Más información
					</Anchor>
				</div>
			</div>
		</section>
		<PatientFeedbackResources />
		<section id='NextAppointment' className='bg-purple text-center'>
			<div className='container container--narrow'>
				<div className='icon'>
					<StethoscopeLargeIcon />
				</div>
				<h2>Siéntase preparado con nuestra guía de análisis para médicos</h2>
				<p>
				Nuestra guía de análisis con el médico que puede descargar puede ayudarlo a iniciar la conversación y hacer preguntas a su médico sobre LUPKYNIS con seguridad. Asegúrese de descargarlo y llevarlo con usted a su próxima cita.
				</p>
				<div>
					<Anchor
						url={getLocalizedURL('doctor-discussion-guide.pdf','es')}
						target='_blank'
						linkClass='button'>
						Descargue la guía
					</Anchor>
				</div>
			</div>
		</section>
		<section id='Organizations' className='bg-white'>
			<div className='container container--narrow'>
				<h2>Organizaciones que lideran el camino</h2>
				<p>
				Establecer contacto con comunidades de iguales y redes de apoyo es una forma estupenda de implicarse, desde sensibilizar sobre la nefritis lúpica hasta ayudarle a sentirse vinculado y apoyado por otras personas que luchan por lo mismo.
				</p>
				<p>
				Tenga en cuenta que no respaldamos ni monitoreamos las actividades y el contenido de los grupos de defensa o sus sitios web.
				</p>
				<div className='col-wrapper'>
					<div className='container'>
						<div>
							<h3>ORGANIZACIONES REGIONALES Y LOCALES:</h3>
							<ul className='resource-list'>
								<li>
									<Anchor
										url='https://kaleidoscopefightinglupus.org/'
										target='_blank'
										requiresExitModal={true}
										relationship='noopener noreferrer'>
										Kaleidoscope Fighting Lupus
									</Anchor>
								</li>
								<li>
									<Anchor
										url='https://looms4lupus.org/'
										target='_blank'
										requiresExitModal={true}
										relationship='noopener noreferrer'>
										Looms for Lupus
									</Anchor>
								</li>
								<li>
									<Anchor
										url='https://lupusne.org/'
										target='_blank'
										requiresExitModal={true}
										relationship='noopener noreferrer'>
										Lupus Foundation New England
									</Anchor>
								</li>
								<li>
									<Anchor
										url='https://lupusla.org/'
										target='_blank'
										requiresExitModal={true}
										relationship='noopener noreferrer'>
										Lupus LA
									</Anchor>
								</li>
								<li>
									<Anchor
										url='https://www.lupusil.org/'
										target='_blank'
										requiresExitModal={true}
										relationship='noopener noreferrer'>
										Lupus Society of Illinois
									</Anchor>
								</li>
								<li>
									<Anchor
										url='https://nwkidneycouncil.org/'
										target='_blank'
										requiresExitModal={true}
										relationship='noopener noreferrer'>
										Northwest Kidney Council
									</Anchor>
								</li>
								<li>
									<Anchor
										url='https://www.lupus.org/chapters'
										target='_blank'
										requiresExitModal={true}
										relationship='noopener noreferrer'>
										Regional Lupus Foundation Chapters
									</Anchor>
								</li>
							</ul>
						</div>
						<div>
							<h3>
							ORGANIZACIONES <br className='desktop-only' />
							NACIONALES:
							</h3>
							<ul className='resource-list'>
								<li>
									<Anchor
										url='https://aakp.org/'
										target='_blank'
										requiresExitModal={true}
										relationship='noopener noreferrer'>
										American Association of Kidney Patients
									</Anchor>
								</li>
								<li>
									<Anchor
										url='https://www.kidneyfund.org/'
										target='_blank'
										requiresExitModal={true}
										relationship='noopener noreferrer'>
										American Kidney Fund
									</Anchor>
								</li>
								<li>
									<Anchor
										url='https://www.hss.edu/charladelupus.asp'
										target='_blank'
										requiresExitModal={true}
										relationship='noopener noreferrer'>
										Charla de Lupus (Lupus Chat)
									</Anchor>
								</li>
								<li>
									<Anchor
										url='https://www.ladainc.org/'
										target='_blank'
										requiresExitModal={true}
										relationship='noopener noreferrer'>
										Lupus &amp; Allied Diseases Association
									</Anchor>
								</li>
								<li>
									<Anchor
										url='https://www.lupuschat.org/'
										target='_blank'
										requiresExitModal={true}
										relationship='noopener noreferrer'>
										Lupus Chat
									</Anchor>
								</li>
								<li>
									<Anchor
										url='https://www.lupus.org/'
										target='_blank'
										requiresExitModal={true}
										relationship='noopener noreferrer'>
										Lupus Foundation of America
									</Anchor>
								</li>
								<li>
									<Anchor
										url='https://www.lupusresearch.org/'
										target='_blank'
										requiresExitModal={true}
										relationship='noopener noreferrer'>
										Lupus Research Alliance
									</Anchor>
								</li>
								<li>
									<Anchor
										url='https://www.kidney.org/'
										target='_blank'
										requiresExitModal={true}
										relationship='noopener noreferrer'>
										National Kidney Foundation
									</Anchor>
								</li>
								<li>
									<Anchor
										url='https://nephcure.org/'
										target='_blank'
										requiresExitModal={true}
										relationship='noopener noreferrer'>
										Nephcure Kidney International
									</Anchor>
								</li>
								<li>
									<Anchor
										url='https://thelupusinitiative.org/'
										target='_blank'
										requiresExitModal={true}
										relationship='noopener noreferrer'>
										The Lupus Initiative
									</Anchor>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div className='container'>
				<hr />
			</div>
		</section>
		<section id='FAQ'>
			<div className='container container--narrow'>
				<h2>Preguntas frecuentes</h2>
				<div className='accordions'>
					<h3>¿QUÉ ES LA NEFRITIS LÚPICA?</h3>
					<Accordion title='¿Qué es la nefritis lúpica?'>
						<p>
						La nefritis lúpica es una inflamación de los riñones causada por el lupus. Con la nefritis lúpica, los riñones pierden la capacidad de funcionar correctamente para eliminar los desechos de la sangre y controlar los fluidos corporales. La enfermedad puede provocar problemas de salud graves, daño renal permanente e incluso insuficiencia renal.
						</p>
					</Accordion>
					<Accordion title='¿Cuáles son los síntomas de la nefritis lúpica?'>
						<p>
						Los signos y síntomas tempranos de la nefritis lúpica pueden ser sutiles o incoherentes y a menudo se superponen con otras enfermedades, por lo que puede haber pocos signos de que algo está mal. Pero los signos y síntomas frecuentes incluyen hinchazón (generalmente en las piernas, los pies o los tobillos), orina espumosa, burbujeante o jabonosa, hematuria (sangre en la orina) y proteína en la orina (denominada proteinuria). Si cree que está experimentando síntomas de nefritis lúpica, hable con su médico. 
						</p>
					</Accordion>
					<Accordion title='¿Cómo se diagnostica la nefritis lúpica?'>
						<p>
						Por lo general, un nefrólogo o reumatólogo diagnostica nefritis lúpica. A menudo se diagnostica mediante el uso de un análisis de orina (para detectar proteínas o sangre en la orina), un análisis de sangre y/o una biopsia de riñón. Hable con su médico sobre los análisis de sangre y orina de rutina, que pueden ayudar a detectar la nefritis lúpica de manera temprana y comenzar a determinar un plan de tratamiento. 
						</p>
					</Accordion>
				</div>
				<div className='accordions'>
					<h3>¿QUÉ ES LUPKYNIS?</h3>
					<Accordion title='¿Qué es LUPKYNIS?'>
						<p>
						LUPKYNIS es el primer medicamento oral aprobado por la FDA específicamente para adultos con nefritis lúpica activa.
						</p>
					</Accordion>
					<Accordion title='¿Quién debe tomar LUPKYNIS?'>
						<p>
						LUPKYNIS está indicado para uso en adultos con nefritis lúpica activa, pero no todas las personas con nefritis lúpica deben tomar LUPKYNIS. Hable con su médico para ver si LUPKYNIS es adecuado para usted.
						</p>
					</Accordion>
					<Accordion title='¿Quién no debe tomar LUPKYNIS?'>
						<p>
						Si está embarazada o planea quedar embarazada, está amamantando actualmente o planea amamantar, hable con su médico sobre sus opciones antes de comenzar su plan de tratamiento. LUPKYNIS no debe tomarse con medicamentos conocidos como inhibidores potentes del CYP3A4 como ketoconazol, itraconazol y claritromicina. No tome LUPKYNIS si es alérgico a la voclosporina o a cualquiera de los ingredientes de LUPKYNIS.
						</p>
					</Accordion>
					<Accordion title='¿Qué hace LUPKYNIS?'>
						<p>
						LUPKYNIS ayuda a detener el ataque de nefritis lúpica y ayuda a proteger los riñones de daños irreversibles adicionales.
						</p>
					</Accordion>
					<Accordion title='¿Cómo actúa LUPKYNIS?'>
						<p>
						LUPKYNIS actúa calmando al sistema inmunitario, lo que hace que el cuerpo ataque las células de los riñones. Ayuda a reducir la inflamación y proteger las células específicas de los riñones para mantenerlas en funcionamiento. 
						</p>
					</Accordion>
					<Accordion title='¿Se estudió LUPKYNIS en varias poblaciones?'>
						<p>
						LUPKYNIS se analizó en estudios clínicos en los que participaron más de 350&nbsp;personas de diferentes razas.
						</p>
					</Accordion>
				</div>
				<div className='accordions'>
					<h3>HABLE CON SU MÉDICO ACERCA DE LUPKYNIS</h3>
					<Accordion title='Cuando hablo con mi médico sobre LUPKYNIS, ¿qué preguntas debo hacer?'>
						<p>
						Creamos una guía de análisis para el médico para descargar, a fin de que pueda sentirse seguro y preparado para hablar con su médico sobre LUPKYNIS. Obtenga la suya {' '}
							<Anchor
								url={getLocalizedURL('doctor-discussion-guide.pdf','es')}
								target='_blank'>
								aquí
							</Anchor>
							.
						</p>
					</Accordion>
					<Accordion title='¿Con quién hablo si tengo preguntas sobre mi tratamiento?'>
						<p>
						Si tiene preguntas específicas sobre LUPKYNIS o cualquier otro aspecto de su tratamiento para la nefritis lúpica, siempre debe hablar con su médico. También puede inscribirse en Aurinia Alliance, un programa de apoyo de Aurinia, los fabricantes de LUPKYNIS, para comunicarse con un administrador de casos exclusivo del personal de enfermería. Un administrador de casos del personal de enfermería (Nurse Case Manager, NCM) asignado personalmente le proporcionará la consistencia y la experiencia necesarias para ayudarlo a transitar el recorrido de su tratamiento con LUPKYNIS. 
						Inscríbase {' '}
							<Link to={getLocalizedURL('registration','es')}>aquí</Link> para comenzar. También puede inscribirse llamando al 1-833-AURINIA (1-833-287-4642).
						</p>
					</Accordion>
				</div>
				<div className='accordions'>
					<h3>
					RECURSOS DE TRATAMIENTO A TRAVÉS DE AURINIA ALLIANCE<sup>&reg;</sup>
					</h3>
					<Accordion title='¿Qué es Aurinia Alliance?'>
						<p>
						Su equipo de Aurinia Alliance se enfoca en proporcionarle lo que necesita en cada paso del recorrido de su tratamiento con LUPKYNIS. Ya sean herramientas educativas, ayudan a comprender su seguro y las opciones de financiamiento o simplemente un oído amigable, comprendemos que su recorrido por la nefritis lúpica es único y estamos aquí para escucharlo y apoyarlo a lo largo del camino. Su equipo incluye apoyo individual de un administrador de casos exclusivo del personal de enfermería que se comunicará con usted periódicamente para responder sus preguntas y ver cómo se siente, y una farmacia especializada que le entregará LUPKYNIS directamente a usted.
						</p>
						<p>
						Inscríbase <Link to={getLocalizedURL('registration','es')}>aquí</Link>. También puede inscribirse llamando al 1-833-AURINIA (1-833-287-4642).
						</p>
					</Accordion>
					<Accordion title='¿Qué es un administrador de casos del personal de enfermería?'>
						<p>
						Cuando se inscriba en Aurinia Alliance, se le asignará un administrador de casos del personal de enfermería. Esta persona será su contacto exclusivo durante todo el recorrido de su tratamiento con LUPKYNIS para ayudarlo a mantenerse organizado y en marcha con su tratamiento. Si está interesado en inscribirse, hágalo {' '}
							<Link to={getLocalizedURL('registration','es')}>aquí</Link> y un administrador de casos exclusivo del personal de enfermería se comunicará con usted para obtener más información. También puede inscribirse llamando al 1-833-AURINIA (1-833-287-4642).
						</p>
					</Accordion>
					<Accordion title='¿Podré pagar el tratamiento con LUPKYNIS'>
						<p>
						La situación de cada persona es diferente, pero cuando se inscribe en Aurinia Alliance, su administrador de casos del personal de enfermería se asociará con usted y le explicará todas las opciones disponibles para usted en función de su cobertura individual.
						</p>{' '}
						<p>
						Aurinia también ofrece un programa de copago para pacientes comerciales elegibles. $0 de copago para pacientes comerciales elegibles.{' '}
						</p>{' '}
						<p>
						En el 97 % de los casos*, los pacientes pagaron menos de $10 por su receta de LUPKYNIS.{' '}
							<Link to={getLocalizedURL('registration','es')}>Inscríbase aquí</Link> para comenzar.
							También puede inscribirse llamando al 1-833-AURINIA (1-833-287-4642).
						</p>{' '}
						<p>
						*El 97 % de los envíos de LUPKYNIS entregados le costaron al paciente $10 o menos. Estos son pacientes con seguro comercial, Medicaid o Medicare desde enero de 2022 hasta diciembre de 2022.
						</p>
					</Accordion>
				</div>
				<div className='accordions'>
					<h3>TOMAR LUPKYNIS</h3>
					<Accordion title='¿Cómo tomo LUPKYNIS?'>
						<p>
						Siempre tome LUPKYNIS según las indicaciones de su médico. Una dosis estándar de LUPKYNIS es de 23.7&nbsp;mg (tres cápsulas de 7.9&nbsp;mg) administradas por vía oral dos veces al día. No rompa, triture, mastique ni disuelva las cápsulas de LUPKYNIS antes de tragarlas. Si bien esta es la dosis estándar, siempre debe tomar la dosis recomendada que le recetó su médico. Cada dosis debe tomarse con 12&nbsp;horas de diferencia, a la misma hora todos los días. LUPKYNIS debe tomarse con el estómago vacío, ya sea 1&nbsp;hora antes de las comidas o 2&nbsp;horas después de comer, a menos que su médico le indique lo contrario.
						</p>
						<p>
						Para mantener la calidad de la cápsula, es importante recordar que nunca debe sacar las cápsulas de LUPKYNIS del blíster hasta que esté listo para tomarlas y no colocar LUPKYNIS en otro recipiente. Si va a estar fuera de su casa cuando llegue el momento de tomar su próxima dosis, planifique con antelación y lleve consigo sus cápsulas de LUPKYNIS envasadas.
						</p>
					</Accordion>
					<Accordion title='¿Qué debo hacer si omito una dosis de LUPKYNIS?'>
						<p>
						Si omitió una dosis, tómela lo antes posible en las 4 horas posteriores a la dosis omitida. Si han transcurrido más de 4&nbsp;horas desde que omitió la dosis, espere hasta la siguiente hora programada para tomar la siguiente dosis, pero no duplique la dosis para compensar la dosis que omitió. 
						</p>
					</Accordion>
					<Accordion title='¿Cuáles son los efectos secundarios más frecuentes mientras toma LUPKYNIS?'>
						<p>
						Los efectos secundarios son diarrea, dolor de cabeza, tos, infección de las vías urinarias, dolor de estómago, acidez estomacal y pérdida del cabello (alopecia). Estos no son todos los posibles efectos secundarios de LUPKYNIS. Hable con su médico sobre cualquier efecto secundario que pueda estar experimentando.
						Consulte la lista completa de posibles efectos secundarios en la{' '}
							<Anchor
								url='https://www.auriniapharma.com/lupkynis-prescribing-information'
								target='_blank'>
								 Información de Prescripción
							</Anchor>
							, incluido el Recuadro de Advertencia y la {' '}
							<Anchor
								url='https://www.auriniapharma.com/lupkynis-medication-guide'
								target='_blank'>
								Guía del Medicamento
							</Anchor>
							.
						</p>
					</Accordion>
				</div>
			</div>
		</section>
	</Layout>
);

export default Resources;
