import React, { useContext } from 'react';
import AppContext from '@src/context'

// Style Imports
import "./PatientFeedbackResources.scss"
// Content Imports
import Image from "@components/content/fractals-image.jsx"

const PatientFeedbackResources = () => {

    const ctx = useContext(AppContext);
    const lang = ctx.currentLang;

    const content = {
        altText: {
            en:'Image of Jennifer, started treatment in 2021',
            es:'Jennifer, quien comenzó el tratamiento en 2021'
        },
        body: {
            en: ["I wouldn't be here without the support of my ", <strong>Aurinia Alliance Nurse Case Manager.</strong>, " She gave me information about LUPKYNIS, answered my questions, and most importantly, coordinated with my insurance and my doctors to help get coverage for LUPKYNIS."],
            es: ["No estaría aquí sin el apoyo de mi ", <strong>administradora de casos del personal de enfermería de Aurinia Alliance.</strong>, " Ella me dio información sobre LUPKYNIS, respondió mis preguntas y, lo que es más importante, coordinó con mi seguro y mis médicos para ayudar a obtener cobertura para LUPKYNIS."],
        },
    };

	return (
		<section id="PatientFeedbackResources" className="bg-light-green">
            <div className="container">
                <div className='patient__image'>
                    <Image data={{
                        desktopSrc: `resources/patient-image${lang==='es'?'-es':''}.png`, 
                        altText: content.altText[lang]
                    }} />
                </div>
                <div className='patient__feedback orange--quotes'>
                    <p>{content.body[lang]}</p>
                </div>
            </div>
        </section>
    );
};

export default PatientFeedbackResources;